@import './colors.scss';

body {
  margin: 0;

  background-color: $color-foreground;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
  white-space: pre-line;
  text-indent: 1.5em;
}