@import './../../assets/styles/colors.scss';

.carded-layout-wrapper {
  display: flex;
  align-items: center;
  background-color: $color-secondary-background;
  box-shadow: 0 0 10px black;
}

.carded-layout-page {
  display: flex;
  align-items: stretch;
}

.react-carousel-dots-holder {
  width: auto!important;
  height: auto!important;
  flex-direction: column;
}

.carded-layout-desc-element {
  max-width: 20vw;
  padding: 1em;
}

.carded-layout-main-element {
  display: flex;
  align-items: center;
}

.carded-layout-main-element > div {
  display: flex;
  align-items: center;
}

.carded-layout-dots {
  margin-left: 0;
  margin-right: 1em;
}