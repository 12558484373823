@import './../../assets/styles/colors.scss';

.background-guitar {
  background: url('./../../assets/images/guitars.jpg') no-repeat center center fixed;
  background-size: cover;
}

.guitars-mobile-content {
  width: 100vw;
}

.guitars-mobile-content > div {
  width: 100vw;
}

.guitars-description {
  background-color: $color-secondary-background;
  text-align: center;
  padding-bottom: 0.5em;
}

.guitars-description > h4 {
  padding-top: 0.5em;
}

.guitars-description > p {
  padding: 0 1em 0 1em;
  white-space: normal;
  text-indent: 0;
  text-align: center;
}

.guitars-description > small {
  color: $color-foreground-secondary;
  margin-bottom: 1em;
}