@import './../../assets/styles/colors';
@import './../../assets/styles/globals.scss';
@import './../../assets/styles/backgrounds.scss';

.navbar {
  background-color: $color-secondary-background;
  box-shadow: 0 0 10px #303030;
  z-index: 1;
}

.b-navbar-container {
  padding: 0.8em;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.navbar-link {
  margin-left: 1em;
  margin-right: 1em;

  font-size: 1.4rem;
  font-family: 'Belleza', 'Arial';
  font-weight: 400;
}

.s-navbar-container {
  display: flex;
  flex-direction: column;
  text-align: center;

  max-height: 8.5rem;

  transition: max-height 0.3s ease;
}

.s-navbar-container > * {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.s-navbar-hidden {
  max-height: 0;
  overflow-y: hidden;
}

.s-navbar-hidden > * {
  opacity: 0;
}

.hamburger--squeeze {
  float: right;
  margin: 0.6rem;
}

.s-visible {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  max-height: 60px;
}

.logo-s {
  margin-left: 0.6em;
  margin-top: 0.2em;
  margin-right: 0.2em;
  max-height: 40px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: #46494c!important;
}