@import 'colors.scss';

.workshop-background {
  background: url('./../../assets/images/Workshop.jpg') no-repeat center center fixed;
  background-size: cover;
}

.wood-background {
  background: url('./../../assets/images/WoodBackground.jpg') no-repeat center center fixed;
  background-size: cover;
}

.rosette-background {
  background: url('./../../assets/images/InfoBackground.jpg') no-repeat center center;
  background-size: cover;
}

.secondary-background {
  background: $color-secondary-background;
  background-size: cover;
}

.maple-background {
  background: url('./../../assets/images/MapleBackground.jpg') no-repeat center center;
  background-size: cover;
}

.rosewood-background {
  background: url('./../../assets/images/RosewoodBackground.jpg') no-repeat center center;
  background-size: cover;
}

.background-none {
  background: none;
}