@import './colors.scss';

* {
  font-family: 'Belleza';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  color: $color-foreground;
}

h1 {
  font-size: 6em;

  line-height: 1;
  margin: 0;

  color: $color-highlight;
  text-align: center;
}

h2 {
  font-size: 3em;

  line-height: 1;
  margin: 0;

  color: $color-secondary-background;
  text-align: center;
}

h3 {
  font-size: 2em;

  line-height: 1;
  margin: 0;
  margin-bottom: 0.3em;

  text-align: center;
}

h4 {
  font-size: 1.4em;

  line-height: 1;
  margin: 0;

  text-align: center;
}

a {
  color: $color-foreground-secondary;
  text-decoration: none;

  transition: 0.20s;
  transition-timing-function: ease-in-out;
  outline: none;
}

a:hover {
  color: $color-highlight;
}

.white-link {
  color: $color-background;
}

.text-justified {
  text-align: justify;
}

.text-dark {
  color: $color-foreground;
}

.text-light {
  color: $color-background;
}