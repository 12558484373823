@import './../../assets/styles/colors';
@import './../../assets/styles/backgrounds';

.footer {
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  background-color: $color-secondary-background;
  
  box-shadow: 0 0 10px #303030;
  z-index: 1;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-links {
  margin-bottom: 0.4em;
}

.social-link {
  margin: 0.2em;
}

.footer-light {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  
  color: $color-secondary-background;
}

.social-link-light {
  margin: 0.2em;
}

.social-link-light > svg > path {
  color: $color-secondary-background;
}