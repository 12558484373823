@import './../../assets/styles/colors.scss';

ul {
  margin: 0;
  padding: 0;
}

.dots-circle {
  background: $color-background;
  border-radius: 50%;
  border: 0 solid $color-foreground-secondary;
}

.dots-circle-active {
  background: $color-background;
  border-radius: 50%;
  border: 0 solid $color-highlight;
}