.margin-lr-1 {
  margin-left: 1em;
  margin-right: 1em;
}

.margin-tb-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.margin-tb-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}

.one-to-one-ratio {
  position: relative;
  padding-top: 100%;
}

.one-to-one-ratio-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.padded-content {
  padding: 4em;
}

.margin-all-4 {
  margin: 4em;
}

.margin-all-3 {
  margin: 3em;
}

.margin-all-2 {
  margin: 2em;
}

.margin-lr-2 {
  margin-left: 2em;
  margin-right: 2em;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.padding-all-1 {
  padding: 1em;
}

.padding-all-2 {
  padding: 2em;
}

.padding-all-4 {
  padding: 4em;
}

.padding-lr-2 {
  padding-left: 2em;
  padding-right: 2em;
}

.padding-lr-5 {
  padding-left: 5em;
  padding-right: 5em;
}

.split-view {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.split-view-l {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.split-view-r {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.split-view-stretched {
  flex: 1;
}