@import './../../assets/styles/colors';

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.cover-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.front-image {
  background: url('./../../assets/images/Front.jpg') no-repeat center center fixed;
  background-size: cover;
}

.cover-container {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.more-info-button {
  margin-top: 0.5em;
  padding: 0.3em;
  border-bottom: 0.15em solid $color-highlight;
}