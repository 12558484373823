@import './../../assets/styles/colors.scss';
@import './../../assets/styles/backgrounds.scss';
@import './../../assets/styles/layout.scss';

.breakout {
  width: 25em;
  height: 25em;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.address {
  display: flex;
  flex-direction: column;
}

.contact-links {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.s-contact-content {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}

.s-contact-map {
  margin-top: 2em;
  height: 400px;
}