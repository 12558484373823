@import './../../assets/styles/colors.scss';

.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card-content-title, .card-content-footer {
  color: $color-foreground;
}

.card-content-description {
  white-space: normal;
  text-indent: 0;
  text-align: center;
}

.card-content-footer {
  text-align: center;
}

.card-content-footer > a {
  color: $color-foreground-secondary;
  text-decoration: underline;
}